<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            :range="true"
            defaultStart="-6M"
            defaultEnd="6M"
            label="실사기간"
            name="conductDts"
            v-model="searchParam.conductDts"
          />
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="자재 실사관리 계획"
      :columns="grid.columns"
      :data="grid.data"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable" label="등록" icon="add" @btnClicked="openPop" />
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'spare-actual',
  data() {
    return {
      searchParam: {
        plantCd: null,
        conductDts: [],
      },
      popupOptions: {
        isFull: false,
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '',
        param: {},
        closeCallback: null,
      },
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            style: 'width:150px',
            sortable: true,
          },
          {
            name: 'conductName',
            field: 'conductName',
            label: '실사관리 계획명',
            align: 'left',
            sortable: true,
            type: 'link'
          },
          {
            name: 'conductDt',
            field: 'conductDt',
            label: '실사 기간',
            align: 'center',
            style: 'width:300px',
            sortable: true,
          },
          {
            name: 'deptName',
            field: 'deptName',
            label: '담당부서',
            align: 'center',
            style: 'width:200px',
            sortable: true,
          },
        ],
        data: [],
      },
      editable: true,
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.sp.conduct.list.url;
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    linkClick(row) {
      this.openPop(row);
    },
    openPop(row) {
      this.popupOptions.title = '자재 실사 상세';
      this.popupOptions.param = {
        conductId: row ? row.conductId : '',
        plantCd: row ? row.plantCd : null,
      };
      this.popupOptions.target = () => import(`${'./spareActualDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
